export const Metadata = () => import('../../components/metadata.vue' /* webpackChunkName: "components/metadata" */).then(c => wrapFunctional(c.default || c))
export const NotFound = () => import('../../components/not-found.vue' /* webpackChunkName: "components/not-found" */).then(c => wrapFunctional(c.default || c))
export const Schemas = () => import('../../components/schemas.vue' /* webpackChunkName: "components/schemas" */).then(c => wrapFunctional(c.default || c))
export const SinglePostListing = () => import('../../components/single-post-listing.vue' /* webpackChunkName: "components/single-post-listing" */).then(c => wrapFunctional(c.default || c))
export const AllBlogFooter = () => import('../../components/all/blog-footer.vue' /* webpackChunkName: "components/all-blog-footer" */).then(c => wrapFunctional(c.default || c))
export const AllBlogHeroAlternate = () => import('../../components/all/blog-hero-alternate.vue' /* webpackChunkName: "components/all-blog-hero-alternate" */).then(c => wrapFunctional(c.default || c))
export const AllBlogHero = () => import('../../components/all/blog-hero.vue' /* webpackChunkName: "components/all-blog-hero" */).then(c => wrapFunctional(c.default || c))
export const AllBlogListingPagination = () => import('../../components/all/blog-listing-pagination.vue' /* webpackChunkName: "components/all-blog-listing-pagination" */).then(c => wrapFunctional(c.default || c))
export const AllBlogListingSinglePost = () => import('../../components/all/blog-listing-single-post.vue' /* webpackChunkName: "components/all-blog-listing-single-post" */).then(c => wrapFunctional(c.default || c))
export const AllBlogNavigation = () => import('../../components/all/blog-navigation.vue' /* webpackChunkName: "components/all-blog-navigation" */).then(c => wrapFunctional(c.default || c))
export const AllBlogSearchOverlay = () => import('../../components/all/blog-search-overlay.vue' /* webpackChunkName: "components/all-blog-search-overlay" */).then(c => wrapFunctional(c.default || c))
export const AllCategoryBlurbs = () => import('../../components/all/category-blurbs.vue' /* webpackChunkName: "components/all-category-blurbs" */).then(c => wrapFunctional(c.default || c))
export const AllFeaturedPostsSection = () => import('../../components/all/featured-posts-section.vue' /* webpackChunkName: "components/all-featured-posts-section" */).then(c => wrapFunctional(c.default || c))
export const All = () => import('../../components/all/index.vue' /* webpackChunkName: "components/all" */).then(c => wrapFunctional(c.default || c))
export const AllNewsletterSignup = () => import('../../components/all/newsletter-signup.vue' /* webpackChunkName: "components/all-newsletter-signup" */).then(c => wrapFunctional(c.default || c))
export const AllPostsSection = () => import('../../components/all/posts-section.vue' /* webpackChunkName: "components/all-posts-section" */).then(c => wrapFunctional(c.default || c))
export const BrandingSalvor = () => import('../../components/branding/salvor.vue' /* webpackChunkName: "components/branding-salvor" */).then(c => wrapFunctional(c.default || c))
export const FinalTest = () => import('../../components/final/test.vue' /* webpackChunkName: "components/final-test" */).then(c => wrapFunctional(c.default || c))
export const IconsNotion = () => import('../../components/icons/notion.vue' /* webpackChunkName: "components/icons-notion" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDashboardFooter = () => import('../../components/layouts/dashboard-footer.vue' /* webpackChunkName: "components/layouts-dashboard-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDashboardHeader = () => import('../../components/layouts/dashboard-header.vue' /* webpackChunkName: "components/layouts-dashboard-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutsDashboardSiteNav = () => import('../../components/layouts/dashboard-site-nav.vue' /* webpackChunkName: "components/layouts-dashboard-site-nav" */).then(c => wrapFunctional(c.default || c))
export const LayoutsFooter = () => import('../../components/layouts/footer.vue' /* webpackChunkName: "components/layouts-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutsHeader = () => import('../../components/layouts/header.vue' /* webpackChunkName: "components/layouts-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutsPageLoading = () => import('../../components/layouts/page-loading.vue' /* webpackChunkName: "components/layouts-page-loading" */).then(c => wrapFunctional(c.default || c))
export const PrimaryBreadcrumbs = () => import('../../components/primary/breadcrumbs.vue' /* webpackChunkName: "components/primary-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const SectionsArticlePage = () => import('../../components/sections/article-page.vue' /* webpackChunkName: "components/sections-article-page" */).then(c => wrapFunctional(c.default || c))
export const SectionsBreadcrumbs = () => import('../../components/sections/breadcrumbs.vue' /* webpackChunkName: "components/sections-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const SectionsCategoryPage = () => import('../../components/sections/category-page.vue' /* webpackChunkName: "components/sections-category-page" */).then(c => wrapFunctional(c.default || c))
export const SectionsFooter = () => import('../../components/sections/footer.vue' /* webpackChunkName: "components/sections-footer" */).then(c => wrapFunctional(c.default || c))
export const SectionsHeader = () => import('../../components/sections/header.vue' /* webpackChunkName: "components/sections-header" */).then(c => wrapFunctional(c.default || c))
export const SectionsSearchBar = () => import('../../components/sections/search-bar.vue' /* webpackChunkName: "components/sections-search-bar" */).then(c => wrapFunctional(c.default || c))
export const TwButton = () => import('../../components/tw/button.vue' /* webpackChunkName: "components/tw-button" */).then(c => wrapFunctional(c.default || c))
export const TwImage = () => import('../../components/tw/image.vue' /* webpackChunkName: "components/tw-image" */).then(c => wrapFunctional(c.default || c))
export const AllUserDashboardSiteBasicInfo = () => import('../../components/all/user-dashboard/site-basic-info.vue' /* webpackChunkName: "components/all-user-dashboard-site-basic-info" */).then(c => wrapFunctional(c.default || c))
export const FinalSubdomainsBreadcrumbs = () => import('../../components/final/subdomains/breadcrumbs.vue' /* webpackChunkName: "components/final-subdomains-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const FinalSubdomainsCategoryList = () => import('../../components/final/subdomains/category-list.vue' /* webpackChunkName: "components/final-subdomains-category-list" */).then(c => wrapFunctional(c.default || c))
export const FinalSubdomainsNavFooter = () => import('../../components/final/subdomains/nav-footer.vue' /* webpackChunkName: "components/final-subdomains-nav-footer" */).then(c => wrapFunctional(c.default || c))
export const FinalSubdomainsNavHeader = () => import('../../components/final/subdomains/nav-header.vue' /* webpackChunkName: "components/final-subdomains-nav-header" */).then(c => wrapFunctional(c.default || c))
export const FinalSubdomainsNavMenu = () => import('../../components/final/subdomains/nav-menu.vue' /* webpackChunkName: "components/final-subdomains-nav-menu" */).then(c => wrapFunctional(c.default || c))
export const FinalSubdomainsSearchBar = () => import('../../components/final/subdomains/search-bar.vue' /* webpackChunkName: "components/final-subdomains-search-bar" */).then(c => wrapFunctional(c.default || c))
export const FinalSubdomainsSearchResults = () => import('../../components/final/subdomains/search-results.vue' /* webpackChunkName: "components/final-subdomains-search-results" */).then(c => wrapFunctional(c.default || c))
export const AllUserDashboardFormSectionsHelpCenterReference = () => import('../../components/all/user-dashboard/form-sections/help-center-reference.vue' /* webpackChunkName: "components/all-user-dashboard-form-sections-help-center-reference" */).then(c => wrapFunctional(c.default || c))
export const AllUserDashboardFormSectionsCategoriesAddCategoryDetails = () => import('../../components/all/user-dashboard/form-sections/categories/add-category-details.vue' /* webpackChunkName: "components/all-user-dashboard-form-sections-categories-add-category-details" */).then(c => wrapFunctional(c.default || c))
export const AllUserDashboardFormSectionsCategoriesListCategories = () => import('../../components/all/user-dashboard/form-sections/categories/list-categories.vue' /* webpackChunkName: "components/all-user-dashboard-form-sections-categories-list-categories" */).then(c => wrapFunctional(c.default || c))
export const AllUserDashboardFormSectionsGeneralNotionConnection = () => import('../../components/all/user-dashboard/form-sections/general/notion-connection.vue' /* webpackChunkName: "components/all-user-dashboard-form-sections-general-notion-connection" */).then(c => wrapFunctional(c.default || c))
export const AllUserDashboardFormSectionsGeneralOnboarding = () => import('../../components/all/user-dashboard/form-sections/general/onboarding.vue' /* webpackChunkName: "components/all-user-dashboard-form-sections-general-onboarding" */).then(c => wrapFunctional(c.default || c))
export const AllUserDashboardFormSectionsGeneralTemplate = () => import('../../components/all/user-dashboard/form-sections/general/template.vue' /* webpackChunkName: "components/all-user-dashboard-form-sections-general-template" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
