import { render, staticRenderFns } from "./dashboard.vue?vue&type=template&id=2d14135a"
import script from "./dashboard.vue?vue&type=script&lang=js"
export * from "./dashboard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutsDashboardHeader: require('/home/ubuntu/master/monorepo_apps/getsalvor.com/components/layouts/dashboard-header.vue').default,LayoutsDashboardFooter: require('/home/ubuntu/master/monorepo_apps/getsalvor.com/components/layouts/dashboard-footer.vue').default})
