import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e0c90d76 = () => interopDefault(import('../pages/root-domain/index.vue' /* webpackChunkName: "pages/root-domain/index" */))
const _5d1be291 = () => interopDefault(import('../pages/sub-domain/index.vue' /* webpackChunkName: "pages/sub-domain/index" */))
const _24ee2e42 = () => interopDefault(import('../pages/root-domain/blog/index.vue' /* webpackChunkName: "pages/root-domain/blog/index" */))
const _a0232c6c = () => interopDefault(import('../pages/root-domain/dashboard/index.vue' /* webpackChunkName: "pages/root-domain/dashboard/index" */))
const _53787c05 = () => interopDefault(import('../pages/root-domain/support/index.vue' /* webpackChunkName: "pages/root-domain/support/index" */))
const _2f54d732 = () => interopDefault(import('../pages/root-domain/test/index.vue' /* webpackChunkName: "pages/root-domain/test/index" */))
const _79960ce1 = () => interopDefault(import('../pages/root-domain/dashboard/login.vue' /* webpackChunkName: "pages/root-domain/dashboard/login" */))
const _4a54574a = () => interopDefault(import('../pages/root-domain/dashboard/register.vue' /* webpackChunkName: "pages/root-domain/dashboard/register" */))
const _fc83cfc4 = () => interopDefault(import('../pages/root-domain/dashboard/settings/index.vue' /* webpackChunkName: "pages/root-domain/dashboard/settings/index" */))
const _43addcf2 = () => interopDefault(import('../pages/root-domain/dashboard/sites/index.vue' /* webpackChunkName: "pages/root-domain/dashboard/sites/index" */))
const _6a2327f6 = () => interopDefault(import('../pages/root-domain/support/hello.vue' /* webpackChunkName: "pages/root-domain/support/hello" */))
const _15c19e95 = () => interopDefault(import('../pages/root-domain/dashboard/sites/new.vue' /* webpackChunkName: "pages/root-domain/dashboard/sites/new" */))
const _7fab3e12 = () => interopDefault(import('../pages/root-domain/dashboard/sites/:site_id/index.vue' /* webpackChunkName: "pages/root-domain/dashboard/sites/:site_id/index" */))
const _357322e2 = () => interopDefault(import('../pages/root-domain/dashboard/sites/:site_id/appearance.vue' /* webpackChunkName: "pages/root-domain/dashboard/sites/:site_id/appearance" */))
const _5376eee0 = () => interopDefault(import('../pages/root-domain/dashboard/sites/:site_id/billing.vue' /* webpackChunkName: "pages/root-domain/dashboard/sites/:site_id/billing" */))
const _a6ab4772 = () => interopDefault(import('../pages/root-domain/dashboard/sites/:site_id/categories.vue' /* webpackChunkName: "pages/root-domain/dashboard/sites/:site_id/categories" */))
const _0c036df0 = () => interopDefault(import('../pages/root-domain/dashboard/sites/:site_id/feedback.vue' /* webpackChunkName: "pages/root-domain/dashboard/sites/:site_id/feedback" */))
const _da8a80e4 = () => interopDefault(import('../pages/root-domain/dashboard/sites/:site_id/settings.vue' /* webpackChunkName: "pages/root-domain/dashboard/sites/:site_id/settings" */))
const _08e9ddef = () => interopDefault(import('../pages/root-domain/dashboard/sites/:site_id/widget.vue' /* webpackChunkName: "pages/root-domain/dashboard/sites/:site_id/widget" */))
const _77c7ca92 = () => interopDefault(import('../pages/root-domain/support/_.vue' /* webpackChunkName: "pages/root-domain/support/_" */))
const _187bb962 = () => interopDefault(import('../pages/root-domain/blog/_.vue' /* webpackChunkName: "pages/root-domain/blog/_" */))
const _6edc571e = () => interopDefault(import('../pages/sub-domain/_.vue' /* webpackChunkName: "pages/sub-domain/_" */))
const _1d7b67d2 = () => interopDefault(import('../pages/root-domain/_.vue' /* webpackChunkName: "pages/root-domain/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/root-domain",
    component: _e0c90d76,
    name: "root-domain"
  }, {
    path: "/sub-domain",
    component: _5d1be291,
    name: "sub-domain"
  }, {
    path: "/root-domain/blog",
    component: _24ee2e42,
    name: "root-domain-blog"
  }, {
    path: "/root-domain/dashboard",
    component: _a0232c6c,
    name: "root-domain-dashboard"
  }, {
    path: "/root-domain/support",
    component: _53787c05,
    name: "root-domain-support"
  }, {
    path: "/root-domain/test",
    component: _2f54d732,
    name: "root-domain-test"
  }, {
    path: "/root-domain/dashboard/login",
    component: _79960ce1,
    name: "root-domain-dashboard-login"
  }, {
    path: "/root-domain/dashboard/register",
    component: _4a54574a,
    name: "root-domain-dashboard-register"
  }, {
    path: "/root-domain/dashboard/settings",
    component: _fc83cfc4,
    name: "root-domain-dashboard-settings"
  }, {
    path: "/root-domain/dashboard/sites",
    component: _43addcf2,
    name: "root-domain-dashboard-sites"
  }, {
    path: "/root-domain/support/hello",
    component: _6a2327f6,
    name: "root-domain-support-hello"
  }, {
    path: "/root-domain/dashboard/sites/new",
    component: _15c19e95,
    name: "root-domain-dashboard-sites-new"
  }, {
    path: "/root-domain/dashboard/sites/:site_id",
    component: _7fab3e12,
    name: "root-domain-dashboard-sites-:site_id"
  }, {
    path: "/root-domain/dashboard/sites/:site_id/appearance",
    component: _357322e2,
    name: "root-domain-dashboard-sites-:site_id-appearance"
  }, {
    path: "/root-domain/dashboard/sites/:site_id/billing",
    component: _5376eee0,
    name: "root-domain-dashboard-sites-:site_id-billing"
  }, {
    path: "/root-domain/dashboard/sites/:site_id/categories",
    component: _a6ab4772,
    name: "root-domain-dashboard-sites-:site_id-categories"
  }, {
    path: "/root-domain/dashboard/sites/:site_id/feedback",
    component: _0c036df0,
    name: "root-domain-dashboard-sites-:site_id-feedback"
  }, {
    path: "/root-domain/dashboard/sites/:site_id/settings",
    component: _da8a80e4,
    name: "root-domain-dashboard-sites-:site_id-settings"
  }, {
    path: "/root-domain/dashboard/sites/:site_id/widget",
    component: _08e9ddef,
    name: "root-domain-dashboard-sites-:site_id-widget"
  }, {
    path: "/root-domain/support/*",
    component: _77c7ca92,
    name: "root-domain-support-all"
  }, {
    path: "/root-domain/blog/*",
    component: _187bb962,
    name: "root-domain-blog-all"
  }, {
    path: "/sub-domain/*",
    component: _6edc571e,
    name: "sub-domain-all"
  }, {
    path: "/root-domain/*",
    component: _1d7b67d2,
    name: "root-domain-all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
