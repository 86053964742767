
import { getAuth, signOut } from "firebase/auth";
export default {
    watch: {
        $route(to, from) {
            if (to.path === '/dashboard/login' || to.path === '/dashboard/register' || to.path === '/dashboard/forgot-password' || to.path === '/dashboard/reset-password' || to.path === '/dashboard/verify-account' || to.path === '/dashboard/verify-email') {
                this.logo_click_url = '/';
            } else {
                this.logo_click_url = '/dashboard';
            }
        }
    },
    methods: {
        async logout() {
            const auth = getAuth();
            await signOut(auth);
        }
    },
    mounted() {
        const auth = getAuth();
        auth.onAuthStateChanged((user) => {
            const unprotected_paths = ['/dashboard/login', '/dashboard/register', '/dashboard/forgot-password', '/dashboard/reset-password', '/dashboard/verify-account', '/dashboard/verify-email'];
            if (user) {
                this.is_user_logged_in = true;
                this.logo_click_url = '/dashboard';
                if (unprotected_paths.includes(this.$route.path)) {
                    this.$router.push('/dashboard').catch(()=>{});
                }
            } else {
                this.is_user_logged_in = false;
                this.logo_click_url = '/';
                if (!unprotected_paths.includes(this.$route.path)) {
                    this.$router.push('/dashboard/login').catch(()=>{});
                }
            }
        });
    },
    data() {
        return {
            is_user_logged_in: false,
            logo_click_url: '/',
        }
    },
}
