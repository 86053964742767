import { initializeApp, getApps, getApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore";
// import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBugdoWG7NHWsxnGhOnITWM-bTOIFg_mM4",
    authDomain: "salvor-kb.firebaseapp.com",
    projectId: "salvor-kb",
    storageBucket: "salvor-kb.appspot.com",
    messagingSenderId: "542276122688",
    appId: "1:542276122688:web:470fe473a89a422a5347a5"
};

const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
// const db = getFirestore(app);
// const auth = getAuth(app);

