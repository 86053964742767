export const state = () => ({
    basic_details: {
        id: null,
        name: "TryWilder Support",
        description: "Support website for TryWilder",
        url: null,
        url_type: "default",
        default_url: "trywilder.getsalvor.com",
    },
    seo_details: {
        title: null,
        title_suffix: null,
        description: null,
        keywords: null,
        logo: null,
        favicon: null,
        og_image: null,
    },
    onboarding: {
        steps: [],
        completed: false,
    },
    notion: {
        duplication_status: false,
        setup_complete: false,
        connection_made: false,
        parent_page_identified: false,
        posts_db_identified: false,
        parent_page: null,
        posts_db: null,
    },
    categories: []
})
export const getters = {
}
export const mutations = {
    set_basic_details(state, payload) {
        state.basic_details = {...state.basic_details, ...payload};
    },
    set_onboarding_details(state, payload) {
        state.onboarding = {...state.onboarding, ...payload};
    },
    set_notion_details(state, payload) {
        state.notion = {...state.notion, ...payload};
    },
    set_categories(state, payload) {
        state.categories = [...state.categories, ...payload]
    },
    set_seo_details(state, payload) {
        state.seo_details = {...state.seo_details, ...payload};
    }
}
export const actions = {
}
/*
// { name: "General Info", slug: "general-info", description: "General support questions", icon: "help", parent: null, order: 100 },
// { name: "Getting Started", slug: "getting-started", description: "Getting started with Salvor", icon: "help", parent: null, order: 200 },
// { name: "Integrations", slug: "integrations", description: "Integrating Salvor with other services", icon: "help", parent: null, order: 300 },
// { name: "Guides", slug: "guides", description: "Guides for using Salvor", icon: "help", parent: null, order: 400 },
// { name: "Account", slug: "account", description: "Account related questions", icon: "help", parent: null, order: 500 },
// { name: "About", slug: "about", description: "About Salvor", icon: "help", parent: "general-info", order: 101 },
// { name: "Payments", slug: "payments", description: "Payments and billing", icon: "help", parent: "general-info", order: 102 },
// { name: "Security", slug: "security", description: "Security and privacy", icon: "help", parent: "general-info", order: 103 },
// { name: "Creating New Workspace", slug: "creating-new-workspace", description: "Creating a new workspace", icon: "help", parent: "getting-started", order: 201 },
// { name: "Adding Team Members", slug: "adding-team-members", description: "Adding team members", icon: "help", parent: "getting-started", order: 202 },
// { name: "Managing Team", slug: "managing-team", description: "Managing your team", icon: "help", parent: "getting-started", order: 203 },
// { name: "Deployment", slug: "deployment", description: "Deployment", icon: "help", parent: "getting-started", order: 204 },
// { name: "Slack", slug: "slack", description: "Integrating with Slack", icon: "help", parent: "integrations", order: 301 },
// { name: "Google Drive", slug: "google-drive", description: "Integrating with Google Drive", icon: "help", parent: "integrations", order: 302 },
// { name: "Github", slug: "github", description: "Integrating with Github", icon: "help", parent: "integrations", order: 303 },
// { name: "NuxtJS", slug: "nuxtjs", description: "Using Salvor with NuxtJS", icon: "help", parent: "guides", order: 401 },
// { name: "NextJS", slug: "nextjs", description: "Using Salvor with NextJS", icon: "help", parent: "guides", order: 402 },
// { name: "WordPress", slug: "wordpress", description: "Using Salvor with WordPress", icon: "help", parent: "guides", order: 403 },
// { name: "Firebase", slug: "firebase", description: "Using Salvor with Firebase", icon: "help", parent: "guides", order: 404 },
// { name: "AWS", slug: "aws", description: "Using Salvor with AWS", icon: "help", parent: "guides", order: 405 },
// { name: "Vercel", slug: "vercel", description: "Using Salvor with Vercel", icon: "help", parent: "guides", order: 406 },
// { name: "Creating An Account", slug: "creating-an-account", description: "Creating an account", icon: "help", parent: "account", order: 501 },
// { name: "Deleting Your Account", slug: "deleting-your-account", description: "Deleting your account", icon: "help", parent: "account", order: 502 },
// { name: "Account Management", slug: "account-management", description: "Managing your account", icon: "help", parent: "account", order: 503 },
// { name: "Subscription & Billing", slug: "subscription-and-billing", description: "Subscription and billing", icon: "help", parent: "account", order: 504 },
*/